import React, { Fragment } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Link from "../navigation/link.component"
import styled from "styled-components"
import { colors } from "../structure/theme"
import darken from "polished/lib/color/darken"
import { useTranslation } from "../translation/translate.component"
import Block from "../../components/block/block.component"

const H2 = styled.h2`
  color: ${colors(`primary`)};
  padding-left: 20px;
`

const H3 = styled.h3`
  color: ${colors(`primary`)};
  padding-left: 40px;
`

const SitemapLink = styled(Link)`
  color: ${colors(`primary`)};
  text-decoration: none;
  position: relative;

  ::after {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    content: "";
    height: 1px;
    background-color: ${colors(`primary`)};
    transition: width ease 0.25s;
  }

  :visited {
    color: ${props => darken(0.2, props.theme.colors.primary)};

    ::after {
      background-color: ${props => darken(0.2, props.theme.colors.primary)};
    }
  }

  :hover {
    ::after {
      width: 100%;
    }
  }
`

export default function Sitemap () {
  const { t } = useTranslation()
  const { allCategoryPage } = useStaticQuery(query)

  return (
    <Block blockTitle={t(`common:seo.title`)} blockTitleSeo="h1">
      {allCategoryPage.nodes.map(categoryPage => (
        <Fragment key={categoryPage.slug}>
          <H2 data-testid="category">
            <SitemapLink route="category" params={categoryPage}>
              {categoryPage.title}
            </SitemapLink>
          </H2>
          {categoryPage.categoryPageProducts.map(product => (
            <H3 key={product.slug} data-testid="product">
              <SitemapLink route="product" params={product}>
                {product.name}
              </SitemapLink>
            </H3>
          ))}
        </Fragment>
      ))}
    </Block>
  )
}

const query = graphql`
  query {
    allCategoryPage(filter: { indexed: { eq: true } }) {
      nodes {
        title
        slug
        categoryPageProducts {
          slug
          name
        }
      }
    }
  }
`
